<template>
  <v-main>
    <h2 class="mt-2 mb-8 titleColor--text">eSocial - Estabelecimentos</h2>

    <v-card>
      <TableSearch
        class="estabelecimentos-table-search"
        label="Pesquisar..."
        v-model="search"
        :delay="0"
      >
      </TableSearch>

      <v-data-table
        :headers="headers"
        :items="estabelecimento"
        :items-per-page="15"
        :search="search"
        :loading="myloadingvariable"
        :footer-props="{
          'items-per-page-options': [10, 25, 50],
        }"
      >
        <template v-slot:item.cnpjFilial="{ item }">
          {{ item.cnpjFilial | cnpj }}
        </template>
        <template v-slot:item.nrInscIdeempregador="{ item }">
          {{ item.nrInscIdeempregador | cnpj }}
        </template>
      </v-data-table>
    </v-card>
  </v-main>
</template>

<script>
import eSocialService from '@/services/eSocialService.js';

export default {
  name: 'Estabelecimentos',

  components: {
    TableSearch: () => import('../commons/TableSearch.vue'),
  },

  data() {
    return {
      myloadingvariable: true,
      dialoEstabInform: false,
      search: '',
      headers: [
        { text: 'CNPJ filial', value: 'cnpjFilial', sortable: false },
        { text: 'CNPJ matriz', value: 'nrInscIdeempregador', sortable: false },
        // { text: 'Razão social matriz', align: 'start', value: 'nmRazaoInfoeSocial' },
        // { text: 'UF', align: 'start', value: 'estado' },
        // { text: 'Cidade', align: 'start', value: 'cidade' },
      ],

      estabelecimento: [
        {
          nmRazaoInfoeSocial: '',
          nrInscIdeempregador: '',
          cnpjFilial: '',
        },
      ],
    };
  },

  mounted() {
    this.carregarGrid();
  },

  methods: {
    async carregarGrid() {
      //Pega dados do API
      let response = await eSocialService.empresas.get();
      let cnpjsFiltrados = response.data;

      // Pega dados do Json
      // let cnpjsFiltrados = EstabelecimentoJson;

      let filtroFilial = cnpjsFiltrados.filter((v, i, a) => a.findIndex((t) => t.cnpjFilial === v.cnpjFilial) === i);

      let filteredFiliais = filtroFilial.map(function (obj) {
        var rObjFilial = {};
        (rObjFilial['nrInscIdeempregador'] = parseInt(obj.cnpjMatriz)),
          (rObjFilial['nmRazaoInfoeSocial'] = obj.socialName);
        rObjFilial['cnpjFilial'] = obj.cnpjFilial;
        rObjFilial['cidade'] = obj.city;
        rObjFilial['estado'] = obj.stateCode;

        return rObjFilial;
      });

      this.estabelecimento = filteredFiliais;
      this.myloadingvariable = false;
    },
  },
};
</script>

<style scoped lang="scss">

.estabelecimentos-table-search{
  max-width: 550px;
}

</style>